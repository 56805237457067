import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "7",
  height: "5",
  viewBox: "0 0 7 5",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M6.25695 0.0415039L3.89485 3.10617L1.53274 0.0415039L0.807129 0.984992L3.89485 4.99984L6.98257 0.984992L6.25695 0.0415039Z",
      fill: "#666666"
    }, null, -1)
  ])))
}
export default { render: render }